import React from "react";
import AppcheckBase from "@src/layouts/AppcheckBase";

function AppcheckSchoolStory() {
  return (
    <AppcheckBase>
      <a
        id="app-trigger"
        href="https://teach.classdojo.com/#/schools/story"
        data-app="https://teach.classdojo.com/#/schools/story"
        data-app-ios="classdojo://t/sc/st/"
        data-app-android="https://bnc.lt/school-story-android"
        data-store-android="com.classdojo.android"
        data-store-ios="552602056"
        style={{ color: "#fff" }}
      >
        ClassDojo App
      </a>
    </AppcheckBase>
  );
}

export default AppcheckSchoolStory;
